import './index.scss';
import AnimatedLetters from '../AnimatedLetters'
import { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCss3, faGitAlt, faHtml5, faJsSquare, faPython, faReact } from '@fortawesome/free-brands-svg-icons';
const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <div className='container about-page' >
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                        idx={15}
                    />
                </h1>
                <p>

                    Fully bilingual (French & English) software developer & data scientist.
                    In my previous role, I produced production code,
                    creating end-to-end services using Python, SQL, RabbitMQ and deployment
                    tools such as Docker and Kubernetes in a Hasura based API. My demonstrated 
                    expertise lies in developing micro-services that adhere to SDLC and CI/CD practices 
                    (utilizing Git and Jira).

                    <p>
                        I'm a team player with a demonstrated ability in collaborating with cross-functional teams and utilizing my software 
                        development skills to deliver high-quality code on time. Having a strong background in sports management, 
                        I have developed valuable soft/technical skills that seamlessly transfer over to software development, 
                        including effective communication, strategic planning, and the ability to perform under pressure. 
                        I have an expertise in achieving 
                        solutions forsoftware development and data science problems. My current tech stack is as follows :
                    </p>
                    <p>
                        Back-end: Python, SQL, C#, .NET, rabbitMQ, Postgres, MySQL
                    </p>
                    <p>
                        DevOps : Docker, Kubernetes, Kind, AWS S3, Firebase
                    </p>
                    <p>
                        Front-end : JS, React, HTML5, CSS
                    </p>


                </p>
                <p>
                    Please reach out to me for any back-end software engineer positions or data scientist positions.
                    With demonstrated experience in both front-end and back-end software development, I'm
                    seeking opportunities that will allow me to continue to grow and apply my expertise.

                </p>

                <p>
                    I'm currently learning & developing software on a C# & .NET stack. Please see samples of my work on Github !
                </p>

            </div>
            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faPython} color="#DD0031" />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGitAlt} color="#EC4" />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default About