import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAze8lDACEJilfkaQce6AK1TBD-EAHRhGg",
  authDomain: "react-portfolio-ev.firebaseapp.com",
  projectId: "react-portfolio-ev",
  storageBucket: "react-portfolio-ev.appspot.com",
  messagingSenderId: "944576874907",
  appId: "1:944576874907:web:3f668047b89a9b76ec393e"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const signInWithGoogle = () => signInWithPopup(auth, provider);
