import  './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const sendEmail = (e) => {

        e.preventDefault()

        emailjs
          .sendForm(
            'service_ehcjcon',
            'template_641r4er',
            refForm.current,
            'T_K-64fxBHxfR6OZl',
          )
          .then(

            () => {
                alert('Message sucessfully sent !')
                window.location.reload(false)
            },
            () => {
                alert('Failed to send the message, please try again')
            }
          )
    }
    return (
        <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                      letterClass={letterClass}
                      strArray={['C', 'o','n','t','a','c','t', ' ', 'm', 'e']}
                      idx={15} 
                    />
                </h1>
                <p>
                    I am seeking opportunities as a software developper & data scientist in large scale
                    organisations or startups. To support my skillset and continue to develop my expertise,
                    I am primarily interested in back-end development positions as well as junior full-stack roles.
                </p>
                <div className='contact-form'>
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input
                                type='text'
                                name='name'
                                placeholder='Name'
                                required
                                >
                                </input>

                            </li>
                            <li className='half'>
                                <input
                                type="email"
                                name="email"
                                placeholder='Email'
                                required
                                >
                                </input>

                            </li>
                            <li>
                                <input
                                placeholder='Subject'
                                type='text'
                                name='subject'
                                required
                                >
                                </input>
                            </li>
                            <li>
                                <textarea 
                                placeholder='Message'
                                name="message"
                                required
                                >

                                </textarea>
                            </li>
                            <li>
                                <input
                                type='submit'
                                className='flat-button'
                                value="SEND"
                                >
                                </input>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div className='info-map'>
                Eric Vincent
                <br></br>
                Montreal, Quebec,
                <br></br>
                Canada <br></br>
                <span>evincent18@gmail.com</span>

            </div>
            <div className='map-wrap'>
                <MapContainer center={[45.51767472059558, -73.61576435808239]} zoom={13}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[45.51767472059558, -73.61576435808239]}>

                </Marker>
                </MapContainer>


            </div>
        </div>
        </>
    )
}

export default Contact